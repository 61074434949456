.swal2-shown,
.ReactModal__Body--open {
	#root {
		//filter: blur(5px);
	}
}
.swal2-container.swal2-backdrop-show {
	background-color: rgba(0, 0, 0, 0.6) !important;
	overflow-y: auto !important;
	.swal2-popup {
		display: flex;
	}
}
.swal2-container {
	.swal2-popup {
		height: auto !important;
		width: tovw(750) !important;
		min-height: tovw(300) !important;
		background: url(/images/popup.png) !important;
		background-size: 100% 100% !important;
		padding: tovw(40) tovw(10) 2rem tovw(10) !important;
		.swal2-close,
		.close {
			position: absolute;
			width: 14px;
			height: 14px;
			// background: url(#{$cdnUrl}/images/close.png) no-repeat center;
			background-size: 100%;
			top: 10px;
			right: 10px;
			opacity: 1;
			font-size: 4rem;
			outline: none !important;
			color: #000 !important;
			font-family: serif;
			font-weight: 300;
			z-index: 9;
			text-indent: -9999px;
		}
		#swal2-content {
			font-size: tovw(20);
			color: #fff;
			line-height: 150%;
			text-transform: uppercase;
			p {
				font-size: tovw(20);
				color: #fff;
				line-height: 150%;
				text-transform: uppercase;
				font-family: $roboto;
			}
		}
		#swal2-title,
		h2 {
			font-size: tovw(48);
			font-weight: bold;
			text-align: center;
			text-transform: uppercase;
			margin-left: auto;
			margin-right: auto;
			padding-left: 1rem;
			padding-right: 1rem;
			padding-top: 4%;
			color: #fff;
			justify-content: center;
			font-family: $roboto;
		}
		&.popup-image {
			background: transparent !important;
			box-shadow: none !important;
			width: auto !important;
			padding: 0 !important;
			border-radius: 0.5vw !important;
			overflow: hidden;
			img {
				max-width: 100%;
				margin-left: auto;
				margin-right: auto;
				display: block;
				backface-visibility: hidden;
			}
		}
		&.sale {
			background: url(/images/popup-sale.png) !important;
			background-size: 100% 100% !important;
		}
	}
}
.ReactModal__Overlay {
	display: flex !important;
	position: fixed !important;
	top: 0 !important;
	right: 0 !important;
	bottom: 0;
	left: 0 !important;
	flex-direction: row !important;
	align-items: center !important;
	justify-content: center !important;
	padding: 10px !important;
	background: rgba(0, 0, 0, 0.8) !important;
	z-index: 1060 !important;
	overflow-x: hidden !important;
	-webkit-overflow-scrolling: touch !important;
}
.ReactModal__Content {
	display: flex;
	position: relative;
	flex-direction: column;
	font-family: inherit;
	box-sizing: border-box;
	margin: auto;
	width: tovw(568) !important;
	min-height: tovw(300) !important;
	background: url(/images/popup.png) !important;
	background-size: 100% 100% !important;
	border: 0 !important;
	padding: tovw(40) 0 2rem 0 !important;
	text-align: center;
	outline: none;
	font-family: $hcks;
	.modal-description {
		width: 80%;
		margin-left: auto;
		margin-right: auto;
	}
	&.sale {
		background: url(/images/popup-sale.png) !important;
		background-size: 100% 100% !important;
	}
	&.modal-history {
		width: tovw(1200) !important;
		min-height: tovw(610) !important;
		.modal-description {
			width: 90%;
		}
	}
	&.modal-guide {
		padding-bottom: tovw(100) !important;
		&--content {
			padding: 0 tovw(45);
		}
	}
	&.modal-select {
		width: tovw(950) !important;
		min-height: tovw(620) !important;
		&.special {
			width: tovw(1400) !important;
			min-height: tovw(790) !important;
		}

		.modal-description {
			width: 90%;
		}
		&.super-special {
			width: tovw(1900) !important;
			min-height: tovw(790) !important;
			.modal-description {
				width: 96%;
			}
		}
		p {
			margin-bottom: tovw(25);
		}
	}
	&.modal-received-gift {
		width: tovw(980) !important;
		min-height: tovw(365) !important;
		.modal-description {
			width: 90%;
		}
	}
	&.modal-jackpot {
		width: tovw(689) !important;
		min-height: tovw(757) !important;
		background: url(/images/popup-jackpot.png) no-repeat !important;
		background-size: 100% 100% !important;
		position: relative;
		padding-top: tovw(115) !important;
		// border: tovw(3) solid #f4daa4 !important;
		.modal-description {
			width: 90%;
		}
		&.sale {
			background: url('/images/popup-jackpot-sale.png') !important;
			background-size: 100% 100% !important;
			&::before {
				background: url('/images/confetti-sale.png') no-repeat !important;
				background-size: 100% 100% !important;
			}
		}
		p {
			font-size: tovw(22);
			font-family: $roboto !important;
			color: #fff !important;
			text-transform: uppercase;
			margin-bottom: tovw(20);
			margin-left: auto;
			margin-right: auto;
			font-weight: bold;
			width: tovw(250);
			&:last-child {
				margin-bottom: 0;
			}
		}
		img {
			height: tovw(350);
			margin-bottom: tovw(20);
		}
		&::before {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			margin-left: tovw(-25);
			width: tovw(784);
			height: 110%;
			background: url('/images/confetti.png') no-repeat;
			background-size: 100% 100%;
			z-index: -1;
		}
	}
	&.modal-gift-select {
		width: tovw(600) !important;
		min-height: tovw(610) !important;
		.modal-description {
			width: 90%;
		}
		p {
			font-size: tovw(26);
			font-family: $roboto !important;
			color: #fff !important;
			text-transform: uppercase;
			margin-bottom: tovw(20);
			font-weight: bold;
			&.name {
				font-size: tovw(20);
				width: tovw(300);
				margin: 0 auto;
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
		img {
			height: tovw(350);
			margin-bottom: tovw(20);
		}
	}
	&.modal-received-gifts {
		width: tovw(1350) !important;
		min-height: tovw(800) !important;
		.modal-description {
			width: 90%;
		}
	}
	&.modal-mission {
		width: tovw(600) !important;
		min-height: tovw(300) !important;
		.modal-description {
			width: 94%;
		}
	}
	.close {
		position: absolute;
		width: 14px;
		height: 14px;
		// background: url(#{$cdnUrl}/images/close.png) no-repeat center;
		background-size: 100%;
		top: 10px;
		right: 10px;
		opacity: 1;
		font-size: 4rem;
		outline: none !important;
		color: #000 !important;
		font-family: serif;
		font-weight: 300;
		z-index: 9;
		text-indent: -9999px;
	}
	h2 {
		font-size: tovw(68);
		font-weight: bold;
		text-align: center;
		text-transform: uppercase;
		margin-left: auto;
		margin-right: auto;
		padding-left: 1rem;
		padding-right: 1rem;
		color: #fff;
		justify-content: center;
		font-family: $bb;
		// background-image: linear-gradient(62deg, #ff5900, #ff8805);
		// /* filter: drop-shadow(0 20px 30px #28d8ff33); */
		// color: black;
		// -webkit-text-fill-color: transparent;
		// -webkit-background-clip: text;
		// -webkit-box-decoration-break: clone;
	}
	p,
	h3,
	li {
		font-size: tovw(20);
		color: #fff;
		font-family: $roboto;
	}
}
.ScrollbarsCustom-Wrapper {
	right: 0 !important;
}
.ScrollbarsCustom-Track {
	width: tovw(10) !important;
	right: tovw(-35) !important;
	z-index: 1;
	background: transparent !important;
	&:before {
		content: '';
		position: absolute;
		width: 1px;
		background: #fff;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		height: 100%;
	}
	.modal-history & {
		right: tovw(-20) !important;
		&:before {
			background: #fff;
		}
	}
	.modal-received-gifts & {
		right: tovw(-25) !important;
	}
}
.ScrollbarsCustom-Thumb {
	width: 100% !important;
	border-radius: 0.5vw !important;
	background: #fff !important;
	.modal-history & {
		background: #fff !important;
	}
}
